/**
 * Return `false` for `null` or `undefined` values.
 *
 * The magic is in the casting of the return value - TypeScript will understand
 * that the output of `foo.filter(isNotEmpty)` does not contain null.
 */
export const isNotEmpty = <T>(value: T | null | undefined | ""): value is T => {
	return value != null && value !== "";
};

/**
 * Get a "random" number based on a seed.
 *
 * This returns the same number for the same seed, which is needed to render the same
 * markup on the server and the client to avoid weird react hydration behavior.
 */
export function getPseudoRandomValue(seed: number, min: number, max: number) {
	const size = max - min;
	return Math.floor((Math.abs(Math.sin(seed)) * size + min) * 100) / 100;
}

/**
 * Converts a string pathname to an integer value by summing the character codes.
 * This is useful for generating consistent pseudo-random values based on a pathname.
 */
export function pathnameToIntValue(pathname: string): number {
	return pathname
		.split("")
		.map((char) => char.charCodeAt(0))
		.reduce((a, b) => a + b, 0);
}
