import { Link } from "@remix-run/react";
import { type HTMLProps, useEffect, useId, useRef, useState } from "react";
import { useInViewport } from "react-in-viewport";
import type { Image } from "../lib/imageUtils";
import { getPseudoRandomValue } from "../lib/utils";

export function TravelPolaroid({
	imageLoading = "eager",
	image,
	title,
	to,
	randomSeed,
	className = "",
	style = {},
	...rest
}: {
	/**
	 * Enforce the use of a key to sidestep a bunch of state issues that occur
	 * when navigating between pages.
	 *
	 * This stops `imageLoaded` from being `true` from the previous image, preventing
	 * the fade-in effect from happening.
	 */
	key: string;
	image: Image;
	title: string;
	to: string;
	imageLoading?: HTMLImageElement["loading"];
	randomSeed?: number;
} & HTMLProps<HTMLElement>) {
	const id = useId();
	const imageRef = useRef<HTMLImageElement>(null);
	const { inViewport } = useInViewport(imageRef);
	const [imageLoaded, setImageLoaded] = useState(false);

	useEffect(() => {
		const image = imageRef.current;
		if (!image) {
			return;
		}

		if (image.complete) {
			setImageLoaded(true);
		} else {
			const handleLoad = () => {
				setImageLoaded(true);
			};
			image.addEventListener("load", handleLoad);
			return () => {
				image.removeEventListener("load", handleLoad);
			};
		}
	}, []);

	useEffect(() => {
		if (inViewport && imageLoaded) {
			imageRef.current?.classList.remove("opacity-0");
		}
	}, [inViewport, imageLoaded]);

	return (
		<article
			{...rest}
			className={`${className} relative`}
			style={
				randomSeed
					? {
							...style,
							top: getPseudoRandomValue(randomSeed, -15, 15),
							transform: `rotate(${getPseudoRandomValue(
								randomSeed + 50,
								-2,
								2,
							)}deg)`,
						}
					: style
			}
		>
			{/* TODO: Paginate? */}
			<Link
				to={to}
				className="unstyled flex hocus:scale-[1.01] select-none flex-col rounded-sm border border-neutral-100 bg-gradient-to-b from-[#f9f9f9] to-[#f3f3f3] p-[7%] pb-0 hocus:shadow-2xl shadow-xl transition-shadow will-change-transform"
			>
				<div className="overflow-hidden rounded bg-gray-800">
					<img
						ref={imageRef}
						id={id}
						src={image.src.medium}
						width={image.width}
						height={image.height}
						alt=""
						loading={imageLoading}
						className="block aspect-[0.9] object-cover opacity-0 transition-opacity duration-[1200ms]"
					/>
				</div>
				<div className="flex flex-col gap-1 py-[14%] text-center">
					<h2 className="overflow-hidden text-ellipsis font-handwriting text-3xl text-slate-700">
						{title}
					</h2>
				</div>
			</Link>
		</article>
	);
}
